import * as React from "react";
import { hRatio, wRatio } from "../constants/utils";

const Google = ({ height = 60, width = 60 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width * wRatio}
    height={height * hRatio}
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      fill="#4285F4"
      fillRule="evenodd"
      d="M50.528 51.394c5.123-4.846 8.57-11.595 9.318-19.181a31.782 31.782 0 0 0-.464-9.358H31.429v12.391h15.67c-1.18 3.318-3.285 6.166-5.987 8.2l9.416 7.948Z"
      clipRule="evenodd"
    />
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M12.674 34.324c2.343 7.307 8.724 12.548 16.23 12.548 3.737 0 7.195-1.3 10.015-3.506l9.585 8.416C43.318 56.889 36.444 60 28.904 60c-11.36 0-21.207-7.06-26.047-17.371l9.817-8.305Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={47}
      height={26}
      x={2}
      y={34}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12.674 34.324c2.343 7.307 8.724 12.548 16.23 12.548 3.737 0 7.195-1.3 10.015-3.506l9.585 8.416C43.318 56.889 36.444 60 28.904 60c-11.36 0-21.207-7.06-26.047-17.371l9.817-8.305Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#FBBC05"
      fillRule="evenodd"
      d="M3.478 42.826A30.358 30.358 0 0 1 0 28.683a30.36 30.36 0 0 1 3.592-14.359l10.63 7.998a17.853 17.853 0 0 0-1.165 6.36c0 2.191.394 4.291 1.118 6.237L3.478 42.826Z"
      clipRule="evenodd"
    />
    <mask
      id="b"
      width={15}
      height={29}
      x={0}
      y={14}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.478 42.826A30.358 30.358 0 0 1 0 28.683a30.36 30.36 0 0 1 3.592-14.359l10.63 7.998a17.853 17.853 0 0 0-1.165 6.36c0 2.191.394 4.291 1.118 6.237L3.478 42.826Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#EA4335"
      fillRule="evenodd"
      d="M2.914 17.367C7.992 7.044 18.158 0 29.87 0c8.421 0 16.043 3.642 21.549 9.526l-8.828 9.458c-3.24-3.492-7.743-5.657-12.721-5.657-7.678 0-14.225 5.15-16.756 12.38l-10.2-8.34Z"
      clipRule="evenodd"
    />
    <mask
      id="c"
      width={50}
      height={26}
      x={2}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.914 17.367C7.992 7.044 18.158 0 29.87 0c8.421 0 16.043 3.642 21.549 9.526l-8.828 9.458c-3.24-3.492-7.743-5.657-12.721-5.657-7.678 0-14.225 5.15-16.756 12.38l-10.2-8.34Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);
export default Google;
