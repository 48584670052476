import { useNavigate } from "react-router-dom"
import styles from "./styles.module.css"
import { hRatio, triggerEvent, wRatio } from "../../../constants/utils"
import ActionButton from "../../../components/ActionButton"
import UserStore from "../../../stores/UserStore"
import { FadeTransition } from "../../transitions/transitions"
import { motion } from "framer-motion"
import Cloud1 from "../../../adhd_web_flow/icons/Cloud1"
import Cloud2 from "../../../adhd_web_flow/icons/Cloud2"
import QuestionnaireStore from "../../stores/QuestionnaireStore"

function ImprovementScreen(){
    const navigate = useNavigate()
    function handleActionBtnPress(){
        UserStore.refreshUserData();
            
        navigate("/signup_options");
        UserStore.createUserAccount();
        triggerEvent("WTA-User_Email", {
            question: "Email Input",
            question_no: QuestionnaireStore.QUESTIONS.length,
        });
        return;
        // triggerEvent("WTA-Welcome_Screen_Btn");
    }

    return(
        <FadeTransition className={styles.Container} >
            <img
                src={require("../../../assets/loading/loading_bg.webp")}
                style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                zIndex: -1,
                }}
            />
            <div className={styles.Cloud1}>
                <Cloud1 />
            </div> 
            <div className={styles.Cloud2}>
                <Cloud2 />
            </div> 
            <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                <div className={styles.Title}>
                    84%
                </div>
                <div className={styles.SubTitle}>
                    users see an increase in 
                    their mental wellbeing 
                    levels in just 4 weeks!
                    <br />
                </div>
            </div>
            
            <img 
                src={require('../../../assets/new_onboarding/image_graph.webp')}
                className={styles.Graph}
            />
            <ActionButton
                text={"next"}
                containerStyle={{
                    padding: `0px ${92 * wRatio}px`,
                    bottom: 80 * hRatio,
                }}
                onClick={handleActionBtnPress}
            />
        </FadeTransition>
    )
}

export default ImprovementScreen