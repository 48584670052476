import { useNavigate } from "react-router-dom"
import styles from "./styles.module.css"
import { hRatio, triggerEvent } from "../../../constants/utils"
import ActionButton from "../../../components/ActionButton"
import UserStore from "../../../stores/UserStore"
import { SlideTransition } from "../../transitions/transitions"
import { motion } from "framer-motion"

function WelcomeScreen(){
    const navigate = useNavigate();

    function handleBtnPress(){
        UserStore.refreshUserData();
            
        navigate("/new_onboarding/questions");
        triggerEvent("WTA-Welcome_Screen_Btn");
    }

    return(
        <div className={styles.WelcomeScreenBG}>
            <img
                src={require("../../../assets/new_onboarding/splash screen.webp")}
                style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                zIndex: -1,
                }}
            />

            <div style={{width:"100%"}}>
                <div style={{marginTop: `calc(-249px * var(--hRatio))`}}>
                    <p className={styles.AdhdTitle1}>
                        welcome to
                    </p>
                    <p>
                        <img src={require("../../../assets/new_onboarding/Evolve_dark.webp")} 
                        alt="" 
                        style={{
                            height:139*hRatio
                        }}
                        />
                    </p>
                    <div className={styles.Divider}/>
                
                    <p className={styles.AdhdTitle2}>
                        We'd love to get
                        <br/>
                        to know you!
                    </p>
                    <p className={styles.AdhdTitle3}>
                        press next to continue
                    </p>
                </div>
                
                <ActionButton
                    text={"next"}
                    onClick={handleBtnPress}
                    containerStyle={{ bottom: 45 * hRatio }}

                />
            </div>
        </div>
    )
}

export default WelcomeScreen