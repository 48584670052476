import { triggerEvent } from "../../constants/utils"
import UserStore from "../../stores/UserStore";

const { makeAutoObservable } = require("mobx");

class QuestionnaireStore {
  QUESTIONS = [
    {
      question: "Which areas of your life do you want to improve?",
      isMultiSelect: true,
      options: [
        { text: "❤️ Relationships" },
        { text: "🤗 Mental health" },
        { text: "💪🏼 Physical health" },
        { text: "💼 Career" },
        { text: "🤔 Something else" },
      ],
    },
    {
      question: "How much sleep do you usually get?",
      options: [
        { text: "🥱 Less than 6 hours" },
        { text: "😊 6-7 hours" },
        { text: "😄 7-8 hours" },
        { text: "😁 More than 8 hours" },
      ],
    },
    {
        question: "What routines can Evolve help you build?",   
        isMultiSelect: true,
        options: [
            {text: "☀️ A morning self-care routine"},
            {text: "🌕 A bedtime self-care routine"},
            {text: "🌇 An evening self-care routine"},
            {text: "🧘 An ADHD self-care routine"},
            {text: "🤔 Something else"},
        ],
    },
    {
        question: "How much time can you devote to this routine?",   
        isMultiSelect: false,
        options: [
            {text: "🌱 0-5 minutes"},
            {text: "🪴 5-10 minutes"},
            {text: "🌲 10-15 minutes"},
            {text: "🌴 15+ minutes"},
        ],
    },
    {
      question: "Which habits do you want to include in this routine?",
      isMultiSelect: true,
      options: [
        { text: "✏️ Journaling" },
        { text: "🤗 Mood tracker" },
        { text: "💙 Gratitude" },
        { text: "💚 Breathing Exercise"},
        { text: "🌈 Affirmation" },
      ],
    },
    {
        question: "Which 21 day self-care programs are most relevant for you?",   
        isMultiSelect: true,
        options: [
            {text: "❤️ Stop procrastinating"},
            {text: "🤗 Increase your self-love"},
            {text: "💪 Dealing with ADHD"},
            {text: "🤩 Accept your authentic self"},
            {text: "🤔 Something else"},
        ],
    },
  ];

  currentQuestionIndex = 0;

  navigate = null;

  constructor() {
    makeAutoObservable(this);
  }

  selectOption(optionIndex) {
    const isMultiSelectStep =
      this.QUESTIONS[this.currentQuestionIndex].isMultiSelect;

    if (!isMultiSelectStep) {
      this.clearCurrentSelections();
    }

    this.QUESTIONS[this.currentQuestionIndex].options[optionIndex].isSelected =
      !this.QUESTIONS[this.currentQuestionIndex].options[optionIndex]
        .isSelected;

    this.QUESTIONS[this.currentQuestionIndex].isComplete =
      this.isStepComplete();
      triggerEvent("WTA-Option_Select", {
        question: this.QUESTIONS[this.currentQuestionIndex].question,
        value: this.QUESTIONS[this.currentQuestionIndex].options[optionIndex].text,
      });
  }

  setNavigation(navigate) {
    this.navigate = navigate;
  }
  handleBackPress = () => {
    triggerEvent("WTA-Question_Back", {
      question: this.QUESTIONS[this.currentQuestionIndex],
      question_no: this.currentQuestionIndex + 1,
    });
    this.currentQuestionIndex -= 1;
  };

  handleNextPress = () => {
    if (this.currentQuestionIndex >= (this.QUESTIONS.length - 1)) {
      if (this.navigate) {
        // this.navigate("/signup_options");
        this.navigate("/new_onboarding/improvement")
      }
      // UserStore.createUserAccount();
      // triggerEvent("WTA-User_Email", {
      //   question: "Email Input",
      //   question_no: this.currentQuestionIndex + 1,
      // });
      return;
    }

    triggerEvent("Signup_Options");
    this.currentQuestionIndex += 1;
  };
  

  isStepComplete = () => {
    let isComplete = false;

    this.QUESTIONS[this.currentQuestionIndex].options.map((item) => {
      if (item.isSelected) {
        isComplete = true;
      }
    });

    return isComplete;
  };

  isNextButtonDisabled = () => {
    if (this.currentQuestionIndex < 6) {
      return !this.QUESTIONS[this.currentQuestionIndex].isComplete;
    } else if (this.currentQuestionIndex >= 6) {
      return !UserStore.email || !UserStore.isEmailValid;
    }

    return false;
  };

  clearCurrentSelections = () => {
    this.QUESTIONS[this.currentQuestionIndex].options.map((item, index) => {
      this.QUESTIONS[this.currentQuestionIndex].options[
        index
      ].isSelected = false;
    });
  };
}

export default new QuestionnaireStore();
