import { hRatio, wRatio } from "../constants/utils";

const Tick = ({ height = 39, width = 61, color = "black" }) => {
  return (
    <svg
      width={width * wRatio}
      height={height * hRatio}
      viewBox="0 0 61 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.8716 1.56341C54.7645 -0.0341153 57.7618 -0.0341153 59.6547 1.70851C61.5479 3.45142 61.39 6.2106 59.4971 7.80813L25.8959 37.1445C24.9496 37.8709 23.6875 38.3065 22.5833 38.3065C21.4791 38.3065 20.2172 37.8709 19.2707 36.9994L1.60271 20.2981C-0.132565 18.7006 -0.132565 15.9411 1.76032 14.1985C3.49559 12.601 6.49299 12.601 8.38588 14.3436L22.7412 27.995L52.8716 1.56341Z"
        fill={color}
      />
    </svg>
  );
};

export default Tick;
