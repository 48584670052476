import Styles from "./styles.module.css"
import iStyles from "./iStyles.module.css"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Observer } from "mobx-react";
import LinearProgress from "../../../components/LinearProgress";
import QuestionnaireStore from "../../stores/QuestionnaireStore";
import MCQOption from "./MCQOption";
import { getMobileOperatingSystem, hRatio } from "../../../constants/utils";
import Footer from "./Footer";
import { SlideIn } from "../../transitions/transitions";
import { AnimatePresence } from "framer-motion";


export default function Questions(){
    const navigate = useNavigate()
    const isiOS = getMobileOperatingSystem() === "iOS";
    useEffect(() => {
        QuestionnaireStore.setNavigation(navigate);
      }, []);

    const QuestionComponent = ({ item }) => {
    return (
        <Observer>
        {() => {
            return (
                <div>
                    <p className={isiOS ? iStyles.Question : Styles.Question}>
                    {item.question}
                    </p>
                    {item.isMultiSelect ? (
                    <p className={isiOS ? iStyles.Subtitle : Styles.Subtitle}>
                        Select all that apply!
                    </p>
                    ) : (
                    <br />
                    )}
                    <div>
                        {item.options.map((item, index) => (
                            <MCQOption
                            isSelected={item.isSelected}
                            key={index}
                            text={item.text}
                            onClick={() => QuestionnaireStore.selectOption(index)}
                            />
                        ))}
                    </div>
                </div>
             
            );
        }}
        </Observer>
    );
    };

    return(
        <Observer>
            {()=>{
                const currentQuestionIndex = QuestionnaireStore.currentQuestionIndex;
                const progress =
                    (currentQuestionIndex / (QuestionnaireStore.QUESTIONS.length + 1)) *
                    100;
                return(
                    <div className={Styles.Background}>
                        <AnimatePresence mode='wait'>
                            <img
                                src={require("../../../assets/new_onboarding/Onboarding IC selection.webp")}
                                style={{ height: "100%", width: "100%", position: "absolute" }}
                            />
                            <div className={Styles.Container}>
                                <div className={Styles.BarContainer}>
                                    <LinearProgress progress={progress} />
                                </div>
                                <QuestionComponent
                                    key={QuestionnaireStore.currentQuestionIndex}
                                    item={QuestionnaireStore.QUESTIONS[currentQuestionIndex]}
                                />
                                <div style={{marginBottom: "-3px"}}>
                                    <Footer/>
                                </div>
                            </div>
                        </AnimatePresence>
                    </div>
                );
            }}
        </Observer>
    );
}