import useRazorpay from "react-razorpay";
import ActionButton from "../../components/ActionButton";
import {
  getMobileOperatingSystem,
  hRatio,
  wRatio,
} from "../../constants/utils";
import ChoosePlans from "./ChoosePlans";
import styles from "./styles.module.css";
import UserReviews from "./UserReviews";
import WhoAreWe from "./WhoAreWe";
import WhyChooseUs from "./WhyChooseUs";
import UserStore from "../../../stores/UserStore";

const ACTION_BTN_STYLE = {
  position: "relative",
  bottom: 54 * hRatio,
  width: 1116 * wRatio,
};

const Premium = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";
  const [Razorpay] = useRazorpay();

  return (
    <div className={styles.Container}>
      <img
        src={require("../../images/embrace-logo.webp")}
        className={styles.EmbraceLogo}
      />
      <WhoAreWe />
      <ChoosePlans />
      <UserReviews />
      <WhyChooseUs />
      <ChoosePlans />
      <ActionButton
        containerStyle={{
          padding: `${54 * hRatio}px ${63 * wRatio}px`,
          bottom: 0,
          position: "fixed",
          maxWidth: 500,
          backgroundColor: '#f6f1e5',
        }}
        text={"Consult an expert"}
        onClick={() => UserStore.createStripeSession()}
      />
      <div style={{ height: 368 * hRatio }} />
    </div>
  );
};

export default Premium;
