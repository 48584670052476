import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { makeAutoObservable } from "mobx";
import axios from "axios";

import UserStore from "./UserStore";
import { triggerEvent } from "../constants/utils";

class PremiumStore {
  PREMIUM_PLANS = [
    {
      id: "price_1QohJgAo1ENGNPJVpXA3vWJF",
      duration: "1 month",
      amount: "1499",
      price: 14.99,
      perDayPrice: 0.5,
      currency: "USD",
      currencySymbol: "$",
    },
    {
      id: "price_1QohKCAo1ENGNPJV35orgfeY",
      duration: "3 months",
      amount: "1999",
      price: 19.99,
      perDayPrice: 0.2,
      strikeThroughPrice: 39.99,
      currency: "USD",
      currencySymbol: "$",
    },
    {
      id: "price_1QohKTAo1ENGNPJVwoHOok9i",
      duration: "1 year",
      amount: "2999",
      price: 29.99,
      perDayPrice: 0.1,
      strikeThroughPrice: 99.99,
      currency: "USD",
      currencySymbol: "$",
      offer: "save 70%",
    },
  ];
  selectedPremiumPlan = this.PREMIUM_PLANS[2];
  razorpayOrderId = null;

  constructor() {
    makeAutoObservable(this);
  }

  handlePlanSelection = (planObj) => {
    this.selectedPremiumPlan = planObj;
    triggerEvent("WTA-Premium_Plan_Select", {
      plan: planObj.price,
    });
  };

  createStripeSession = async () => {
    const base_url = "https://evolve-web-onboarding-backend-server-641054470672.us-central1.run.app"
    const response = await axios
      .post(
        `${base_url}/create-stripe-session-WTA/${this.selectedPremiumPlan.id}/${UserStore.email}/${UserStore.developerId}`
      )
      .then((result) => {
        triggerEvent("WTA-Purchase_Button", {
          plan: this.selectedPremiumPlan.price,
        });
        return result;
      })
      .catch((error) => {
        console.error(error);
      });

    const data = response.data;
    window.location.href = data;
  };
}

export default new PremiumStore();
