import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

import { getMobileOperatingSystem, hRatio, triggerEvent } from "../../constants/utils";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import UserStore from "../../stores/UserStore";
import Evolve from "../../icons/Evolve";
import Email from "../../icons/Email";
import Google from "../../icons/Google";
import { FadeTransition } from "../../new_onboarding/transitions/transitions";

const isiOS = getMobileOperatingSystem() === "iOS";

const SignupOptions = () => {
  const navigate = useNavigate();

  const googleLoginHandler = async (res) => {
    await UserStore.googleSignIn(res);
    navigate("/metrics");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (res) => googleLoginHandler(res),
    onError: (error) => alert(error),
  });

  const handleGoogleSignup = () => {
    googleLogin();
    triggerEvent("Google_Signup_Btn");
  };

  const handleEmailSignup = () => {
    navigate("/signup");
    triggerEvent("Email_Signup_Btn");
  };

  const SignupOptionBtn = ({ text, icon, onClick, style }) => {
    const [isPressed, setIsPressed] = useState(false);

    return (
      <div className={Styles.SignupOptionBtnContainer} style={style}>
        <div
          className={isiOS ? iStyles.SignupOptionBtn : Styles.SignupOptionBtn}
          style={{ opacity: isPressed ? 0.5 : 1 }}
          onClick={onClick}
          onMouseDown={() => setIsPressed(true)}
          onMouseUp={() => setIsPressed(false)}
        >
          <div className={isiOS ? iStyles.SignupBtnIcon : Styles.SignupBtnIcon}>
            {icon}
          </div>
          <p className={isiOS ? iStyles.SignupBtnTxt : Styles.SignupBtnTxt}>
            {text}
          </p>
        </div>
      </div>
    );
  };

  return (
    <FadeTransition className={Styles.SignUpBG}>
      <div className={Styles.EvolveIcon}>
        <Evolve height={isiOS ? 102 : 84} width={isiOS ? 408 : 336} />
      </div>
      <div style={{ height: 130 * hRatio }} />
      <img
        src={require("../../adhd_web_flow/images/tree.webp")}
        className={isiOS ? iStyles.Tree : Styles.Tree}
      />
      <p
        className={isiOS ? iStyles.Title : Styles.Title}
        style={{ marginTop: `${20 * hRatio}px` }}
      >
        Take charge of 
      </p>
      <p className={isiOS ? iStyles.Title : Styles.Title}
        style={{ marginTop: `${17 * hRatio}px` }}
      >your well-being today</p>
      <p
        className={isiOS ? iStyles.SubTitle : Styles.SubTitle}
        style={{ marginTop: `${17 * hRatio}px` }}
      >
        Build habits that help you thrive.
      </p>
      {/* <p className={isiOS ? iStyles.SubTitle : Styles.SubTitle}>
        improve sleep, journal & more
      </p> */}
      <img
        src={require("../../assets/sign_up/sign_up.webp")}
        style={{
          height: "100%",
          width: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <p className={isiOS ? iStyles.FooterTxt : Styles.FooterTxt} style={{marginTop: `${(isiOS ? 800 : 740) * hRatio}px`}}>
        by using Evolve, you agree to our
      </p>
      <a
        className={isiOS ? iStyles.FooterTxt : Styles.FooterTxt}
        style={{ textDecoration: "none", color: "#5A97C2" }}
        href="https://evolveinc.io/terms/"
        target="_blank"
      >
        Terms of service
      </a>
      <SignupOptionBtn
        text={"Continue with Google"}
        icon={<Google height={isiOS ? 72 : 60} width={isiOS ? 72 : 60} />}
        onClick={handleGoogleSignup}
        style={{ bottom: `${(isiOS ? 750 : 880) * hRatio}px` }}
      />
      <SignupOptionBtn
        icon={<Email height={isiOS ? 72 : 60} width={isiOS ? 80 : 69} />}
        text={"Continue with Email"}
        onClick={handleEmailSignup}
        style={{ bottom: `${(isiOS ? 520 : 680) * hRatio}px` }}
      />
    </FadeTransition>
  );
};

export default SignupOptions;
