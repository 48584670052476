import * as React from "react";
import { hRatio, wRatio } from "../constants/utils";

const Email = ({ height = 60, width = 69 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width * wRatio}
    height={height * hRatio}
    viewBox="0 0 69 60"
    fill="none"
  >
    <path
      fill="#6EBAEF"
      d="M64.688 60H4.312C1.933 60 0 57.556 0 54.545V5.455C0 2.444 1.932 0 4.313 0h60.374C67.069 0 69 2.444 69 5.455v49.09C69 57.556 67.068 60 64.687 60Z"
    />
    <path
      fill="#9DD2F7"
      d="M64.688 0H4.312C1.893 0 0 2.168 0 4.94c0 .784.328 1.525.88 1.99l32.36 26.607c.751.617 1.765.617 2.515 0L68.115 6.93c.557-.465.885-1.205.885-1.99C69 2.167 67.107 0 64.687 0Z"
    />
  </svg>
);
export default Email;
