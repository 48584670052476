import { Observer } from "mobx-react";

import {
  getMobileOperatingSystem,
  hRatio,
  wRatio,
} from "../../../constants/utils";
import { PREMIUM_PLANS } from "../config";
import styles from "./styles.module.css";
import CheckCircleFilled from "../../../icons/CheckCircleFilled";
import UserStore from "../../../../stores/UserStore";
import iStyles from "./iStyles.module.css";

const ChoosePlans = () => {
  const isiOS = getMobileOperatingSystem() === "iOS";

  const renderPlans = () => {
    return PREMIUM_PLANS.map((item, index) => {
      return (
        <Observer>
          {() => {
            const isSelected = item.id === UserStore.premiumPlan.id;
            return (
              <div
                className={styles.PlanCard}
                style={{
                  backgroundColor: isSelected ? "#bcdbf6" : "white",
                  marginLeft: index === 0 ? 57 * wRatio : 39 * wRatio,
                }}
                onClick={() => UserStore.onSelectPremiumPlan(item)}
              >
                <div className={styles.JustifyFlex}>
                  <p className={isiOS ? iStyles.PriceTxt : styles.PriceTxt}>
                    <span
                      className={isiOS ? iStyles.Currency : styles.Currency}
                    >
                      $
                    </span>{" "}
                    {item.price}
                  </p>
                  {item.recommended && (
                    <p
                      className={
                        isiOS ? iStyles.RecommendedTxt : styles.RecommendedTxt
                      }
                    >
                      Recommended
                    </p>
                  )}
                </div>
                <div className={styles.Separator} />
                <p
                  className={
                    isiOS ? iStyles.PlanIncludesTxt : styles.PlanIncludesTxt
                  }
                >
                  What's included:
                </p>
                <div style={{ marginTop: 22 * hRatio }}>
                  {item.benefits.map((txt) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleFilled
                        height={39}
                        color={isSelected ? "#31496e" : "#5d905e"}
                      />
                      <p
                        className={
                          isiOS ? iStyles.BenifitsTxt : styles.BenifitsTxt
                        }
                      >
                        {txt}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            );
          }}
        </Observer>
      );
    });
  };

  return (
    <div style={{ marginTop: 154 * hRatio, position: "relative" }}>
      <p className={isiOS ? iStyles.Title : styles.Title}>Choose a plan</p>
      <p className={isiOS ? iStyles.SubTitle : styles.SubTitle}>
        Save over $1,000 compared to insurance co-pays and other assessment
        providers
      </p>
      <div className={styles.PlansContainer}>{renderPlans()}</div>
    </div>
  );
};

export default ChoosePlans;
