import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom'
import WelcomeScreen from './screens/WelcomeScreen'
import Questions from './screens/Questions'
import ImprovementScreen from './screens/Improvement';
import { AnimatePresence } from 'framer-motion';
import { SlideTransition } from './transitions/transitions';

export default function NewOnboardingApp(){
    const location = useLocation()
    return(
        <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={
                    <WelcomeScreen />
                }/>
                <Route path='/questions' element={<Questions />}/>
                <Route path='/improvement' element={<ImprovementScreen />}/>
            </Routes>
        </AnimatePresence>
    );
}
