import PremiumBenefits from "./PremiumBenefits";
import PremiumPlans from "./PremiumPlans";
import Styles from "./styles.module.css";
import UserReviews from "./UserReviews";

const PremiumScreen = () => {
  return (
    <div className={Styles.PremiumScreenContainer}>
      <img
        src={require("../../assets/payment_screen/paywall_new.png")}
        className={Styles.PremiumCoverImage}
      />
      <img
        src={require("../../assets/payment_screen/apple_laurel.png")}
        className={Styles.GooglePlayAward}
      />
      <PremiumPlans />
      <PremiumBenefits />
      <UserReviews />
      <PremiumPlans />
    </div>
  );
};

export default PremiumScreen;
