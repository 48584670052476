export const SCORE_MEANING = {
  low: "A low score indicates fewer symptoms related to ADHD. If you still feel challenged in areas like attention or organization, these could be related to other factors. It's always a good idea to speak with a professional if you're concerned.",
  high: "A high score suggests that you may be experiencing symptoms consistent with ADHD. These symptoms can impact your daily life, affecting attention, focus, organization, and time management. We recommend consulting a healthcare professional for a comprehensive evaluation.",
};

export const SCORE_RECOMMENDATION = {
  low: "Your response does not indicate you have ADHD. However this is a screening assessment. If you believe you are struggling in daily life we highly recommend you speak with our experts.",
  high: "We strongly recommend getting a comprehensive assessment.",
};

export const ACTION_BTN_TXT = {
  low: "See our plans",
  high: "See our plans",
};
