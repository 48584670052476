import axios from "axios";
import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { getMobileOperatingSystem, triggerEvent } from "../constants/utils";
import { makePersistable } from "mobx-persist-store";

class UserStore {
  email = "";
  name = "";
  password = "1234";
  developerId = "";
  isEmailValid = true;
  deviceId = "";
  accessToken = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: ["email", "developerId", "deviceId"],
      storage: window.localStorage,
    })
  }

  setFields = (key, value) => {
    this[key] = value;
  };

  guestOnboard = async () => {
    let ADID =
      getMobileOperatingSystem() === "iOS"
        ? "00000000-0000-0000-0000-000000000000"
        : "00000000-0000-0000-000000000000";

    this.deviceId = uuidv4();
    
    const params = {
      device_id: this.deviceId,
      ad_id: ADID,
      is_web: true,
    };

    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}v3/guest/onboard/`, params, {
        headers: {
          "Content-Type": "application/json",
           "no-encryption": "true"
        },
      })
      .then((res) => {
        this.developerId = res?.data?.developer_id[0];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  createUserAccount = async () => {
    let url =
      `${process.env.REACT_APP_API_BASE_URL}v7/user/webflow/signup/`;
    if (this.developerId === "") {
      await this.guestOnboard();
    }

    const params = {
      email: this.email.toLowerCase(),
      password: this.password,
      password2: this.password,
      profile: {
        is_webflow: true,
        webflow_pwd_created: false,
      },
      guest: {
        developer_id: this.developerId,
      },
      device: {
        device_id: this.deviceId,
      },
    };

    axios
      .post(`${url}`, params,
        {
          headers: {
             "no-encryption": "true"
          }
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  googleSignIn = async googleAuthResponse => {
    axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleAuthResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${googleAuthResponse.access_token}`,
          Accept: 'application/json'
        }
      })
      .then(res => {
        const user = res.data;
        const email = user?.email ? user?.email : '';
        const first_name = user?.given_name ? user.given_name : '';
        const last_name = user?.family_name ? user.family_name : '';
        const registration_provider_id = googleAuthResponse.access_token ? googleAuthResponse.access_token : '';

        this.userSocialContinueApi({
          first_name,
          last_name,
          email,
          registration_provider: 'GOOGLE',
          registration_provider_id,
        });
      })
      .catch((err) => {
        triggerEvent("google sign in failed", err);
        console.log(err);
      });
  }

  userSocialContinueApi = async bodyParams => {
    let url = process.env.REACT_APP_API_BASE_URL;
    let lastName = '.';
    const CLIENT_ID = "AiWaiUrd6zx0BPSDjx0h6jstz0BpSbbOyrTWkkAD";
    const CLIENT_SECRET =
      "VfsYbPZg4nQAP4xOohoqyAV8rGaEgGTTvUP1oxKUaKcfEafoewQ0RHTj1c6c9VkCOu71ByeLU9fi1WOvQMgBewlceHlejGXbi510RMOAd6YvHgw9vDf32YMOCNVy1AvZ";
    const GRANT_TYPE = "password";

    if (this.developerId == '') {
      await this.guestOnboard();
    }

    if (bodyParams.last_name) {
      lastName = bodyParams.last_name.replace('/', '').trim();
    }
    if (lastName.length == 0) {
      lastName = '.';
    }

    const params = {
      email: bodyParams.email,
      'profile.handle': `${Math.random() * (25 - 10) + 10}`,
      'profile.salutation': '',
      'profile.first_name': bodyParams?.first_name
        ? bodyParams.first_name?.replace('/', '').trim()
        : '',
      'profile.middle_name': '',
      'profile.last_name': lastName,
      'profile.gender': '',
      'profile.address_line_one': '',
      'profile.address_line_two': '',
      'profile.registration_provider': bodyParams.registration_provider,
      'profile.registration_provider_id': bodyParams.registration_provider_id,
      'profile.bio': '',
      'profile.facebook_handle': '',
      'profile.instagram_handle': '',
      'profile.linkedin_handle': '',
      'profile.twitter_handle': '',
      'profile.url': '',
      'guest.developer_id': this.developerId,
      'device.device_id': this.deviceId,
      grant_type: GRANT_TYPE,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };

    let qsConvertedString = '';
    Object.entries(params).forEach(([key, value]) => {
      qsConvertedString = `${qsConvertedString}${key}=${value}&`;
    });

    axios.post(`${url}v3/user/social/continue/`, qsConvertedString, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "no-encryption": "true",
      },
    })
      .then(async res => {
        const { data } = res;
        this.developerId = data.developer_id;
      })
      .catch((error) => {
        triggerEvent("social continue api failed", error)
        console.error('tryCatchError userSocialContinueApi', error);
      });
  };

  markUserPremium = () => {
    const params = {
      email: this.email,
    };

    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}v7/user/premium_status/`,
        params,
        {
          headers: {
            "no-encryption": "true"
          }
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  refreshUserData = () => {
    this.email = "";
    this.password = "1234";
    this.developerId = "";
    this.accessToken = "";
    this.deviceId = "";
  }
}

export default new UserStore();
