import Cloud1 from "../../icons/Cloud1";
import Cloud2 from "../../icons/Cloud2";
import styles from "./styles.module.css";

const Background = ({ children }) => {
  return (
    <div className={styles.Background2}>
      <div className={styles.Cloud1}>
        <Cloud1 />
      </div> 
      <div className={styles.Cloud2}>
        <Cloud2 />
      </div> 
      {children}
    </div>
  );
};

const Background2 = ({ children }) => {
  return (
    <div className={styles.Background2}>
      {children}
    </div>
  );
};

const Background3 = ({ children }) => {
  return (
    <div className={styles.Background3}>
      {/* <div className={styles.Cloud1}>
        <Cloud1 />
      </div> */}
      {/* <div className={styles.Cloud2}>
        <Cloud2 />
      </div>  */}
      {children}
    </div>
  );
};

export { Background, Background2, Background3 };
