import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Observer } from "mobx-react";

import LinearProgress from "../../components/LinearProgress";
import Footer from "./Footer";
import MCQOption from "./MCQOption";
import { getMobileOperatingSystem, hRatio } from "../../constants/utils";
import QuestionareStore from "../../stores/QuestionareStore";
import styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";
import { Background3 } from "../../components/Background";
import DataScreen1 from "./DataScreen1";
import DataScreen2 from "./DataScreen2";
import { SlideIn } from "../../../new_onboarding/transitions/transitions";
import { AnimatePresence } from "framer-motion";

const Questionare = () => {
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  useEffect(() => {
    QuestionareStore.setNavigation(navigate);
  }, []);

  const QuestionComponent = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            //<SlideIn key={item.id}>
            <div>
              <p
                className={isiOS ? iStyles.QuestionLabel : styles.QuestionLabel}
              >
                in last 6 months,
              </p>
              <p className={isiOS ? iStyles.Question : styles.Question}>
                {item.question}
              </p>
              <div style={{ marginTop: 20 * hRatio }}>
                {item.options.map((item, index) => (
                  <MCQOption
                    isSelected={item.isSelected}
                    key={index}
                    text={item.text}
                    onClick={() => QuestionareStore.selectOption(index)}
                  />
                ))}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  return (
    <Observer>
      {() => {
        const currentStepIndex = QuestionareStore.currentStepIndex;
        const progress =
          (currentStepIndex / (QuestionareStore.QUESTIONS.length)) * 100;
        return (
          <Background3>
            <AnimatePresence>
              <div className={styles.Container}>
                {QuestionareStore.currentStepIndex === 2 ? (
                  <DataScreen1 />
                ) : QuestionareStore.currentStepIndex === 4 ? (
                  <DataScreen2 />
                ) : (
                  <>
                    <div className={styles.BarContainer}>
                      <LinearProgress progress={progress} />
                    </div>
                      <QuestionComponent
                        key={QuestionareStore.currentStepIndex}
                        item={QuestionareStore.QUESTIONS[currentStepIndex]}
                      />
                    <Footer isQuestionScreen={true} />
                  </>
                )}
              </div>
            </AnimatePresence>
          </Background3>
        );
      }}
    </Observer>
  );
};

export default Questionare;
