import { triggerEvent } from "../constants/utils";
import UserStore from "./UserStore";

const { makeAutoObservable } = require("mobx");

class QuestionareStore {
  QUESTIONS = [
    {
      question: "Which areas of your life do you want to improve?",
      isMultiSelect: true,
      options: [
        { text: "❤️ Relationships" },
        { text: "🤗 Mental health" },
        { text: "💪🏼 Physical health" },
        { text: "💼 Career" },
        { text: "🤔 Something else" },
      ],
    },
    {
      question: "Got it. How much sleep do you usually get?",
      options: [
        { text: "🥱 Less than 6 hours" },
        { text: "😊 6-7 hours" },
        { text: "😄 7-8 hours" },
        { text: "😁 More than 8 hours" },
      ],
    },
    {
      question: "How easily do you get distracted?",
      options: [
        { text: "😵‍💫 Very easily" },
        { text: "😬 I tend to lose focus sometimes" },
        { text: "🧐 I rarely lose focus" },
        { text: "😎 I am not easily distracted" },
      ],
    },
    {
      question: "What positive habits would you like to build?",
      isMultiSelect: true,
      options: [
        { text: "✏️ Journal" },
        { text: "⏳ Meditation" },
        { text: "🥰 Affirmations" },
        { text: "💜 Gratitude" },
        { text: "🤔 Something else" },
      ],
    },
  ];

  currentQuestionIndex = 0;

  navigate = null;

  constructor() {
    makeAutoObservable(this);
  }

  selectOption(optionIndex) {
    const isMultiSelectStep =
      this.QUESTIONS[this.currentQuestionIndex].isMultiSelect;

    if (!isMultiSelectStep) {
      this.clearCurrentSelections();
    }

    this.QUESTIONS[this.currentQuestionIndex].options[optionIndex].isSelected =
      !this.QUESTIONS[this.currentQuestionIndex].options[optionIndex]
        .isSelected;

    this.QUESTIONS[this.currentQuestionIndex].isComplete =
      this.isStepComplete();
      triggerEvent("WTA-Option_Select", {
        question: this.QUESTIONS[this.currentQuestionIndex].question,
        value: this.QUESTIONS[this.currentQuestionIndex].options[optionIndex].text,
      });
  }

  setNavigation(navigate) {
    this.navigate = navigate;
  }
  handleBackPress = () => {
    triggerEvent("WTA-Question_Back", {
      question: this.QUESTIONS[this.currentQuestionIndex],
      question_no: this.currentQuestionIndex + 1,
    });
    this.currentQuestionIndex -= 1;
  };

  handleNextPress = () => {
    if (this.currentQuestionIndex >= (this.QUESTIONS.length - 1)) {
      if (this.navigate) {
        this.navigate("/signup_options");
      }
      UserStore.createUserAccount();
      triggerEvent("WTA-User_Email", {
        question: "Email Input",
        question_no: this.currentQuestionIndex + 1,
      });
      return;
    }

    triggerEvent("Signup_Options");
    this.currentQuestionIndex += 1;
  };

  isStepComplete = () => {
    let isComplete = false;

    this.QUESTIONS[this.currentQuestionIndex].options.map((item) => {
      if (item.isSelected) {
        isComplete = true;
      }
    });

    return isComplete;
  };

  isNextButtonDisabled = () => {
    if (this.currentQuestionIndex < 4) {
      return !this.QUESTIONS[this.currentQuestionIndex].isComplete;
    } else if (this.currentQuestionIndex >= 4) {
      return !UserStore.email || !UserStore.isEmailValid;
    }

    return false;
  };

  clearCurrentSelections = () => {
    this.QUESTIONS[this.currentQuestionIndex].options.map((item, index) => {
      this.QUESTIONS[this.currentQuestionIndex].options[
        index
      ].isSelected = false;
    });
  };
}

export default new QuestionareStore();
