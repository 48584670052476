import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { Observer } from "mobx-react";

import { getMobileOperatingSystem, hRatio } from "../../../constants/utils";
import CheckCircle from "../../../icons/CheckCircle";
import CheckCircleFilled from "../../../icons/CheckCircleFilled";
import LockIcon from "../../../icons/LockIcon";
import PremiumStore from "../../../stores/PremiumStore";
import Styles from "./styles.module.css";
import iStyles from "./iStyles.module.css";

const PremiumPlans = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const isiOS = getMobileOperatingSystem() === "iOS";

  const PremiumPlanCard = ({ style, isSelected, offer, data }) => {
    return (
      <div
        className={Styles.PremiumCard}
        style={{
          ...style,
          backgroundColor: isSelected ? "#6ABAF2" : "rgba(0, 0, 0, 0.08)",
          height: offer
            ? isiOS
              ? 459 * hRatio
              : 439 * hRatio
            : isiOS
            ? 315 * hRatio
            : 295 * hRatio,
        }}
        onClick={() => PremiumStore.handlePlanSelection(data)}
      >
        {offer && (
          <div className={Styles.Save50}>
            <p className={isiOS ? iStyles.Save50Text : Styles.Save50Text}>
              {offer}
            </p>
          </div>
        )}
        <div className={Styles.FlexSpaceBetween}>
          <div>
            <div className={Styles.FlexRow} style={{ alignItems: "center" }}>
              {isSelected ? <CheckCircleFilled /> : <CheckCircle />}
              <p
                className={
                  isiOS ? iStyles.PremiumDuration : Styles.PremiumDuration
                }
              >
                {data.duration}
              </p>
            </div>
            <div className={Styles.FlexRow}>
              <p
                className={isiOS ? iStyles.PerDayPrice : Styles.PerDayPrice}
              >{`${data.currencySymbol} ${data.perDayPrice}/day`}</p>
            </div>
          </div>
          <div className={Styles.DayPrice}>
            <div className={Styles.FlexRow} style={{ alignItems: "center" }}>
              <p
                className={
                  isiOS ? iStyles.PerDayPriceSymbol : Styles.PerDayPriceSymbol
                }
              >
                {data.currencySymbol}
              </p>
              <p className={isiOS ? iStyles.PremiumPrice : Styles.PremiumPrice}>
                {data.price}
              </p>
            </div>
            <p
              className={
                isiOS ? iStyles.PremiumCutPrice : Styles.PremiumCutPrice
              }
            >
              {data.strikeThroughPrice
                ? `${data.currencySymbol}${data.strikeThroughPrice}`
                : ""}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Observer>
      {() => (
        <div className={Styles.PremiumPlansContainer}>
          <p
            className={
              isiOS ? iStyles.PremiumPlansTitle : Styles.PremiumPlansTitle
            }
          >
            Choose your plan
          </p>
          <div className={Styles.PremiumPlans}>
            {PremiumStore.PREMIUM_PLANS.map((item, index) => {
              return (
                <PremiumPlanCard
                  key={item.id}
                  style={index > 0 ? { marginTop: 63 * hRatio } : {}}
                  isSelected={item.id === PremiumStore.selectedPremiumPlan.id}
                  offer={item.offer}
                  data={item}
                />
              );
            })}
          </div>
          <div
            className={Styles.SubscribeBtn}
            onClick={() =>
              PremiumStore.createStripeSession()
            }
          >
            <LockIcon />
            <p
              className={
                isiOS ? iStyles.SubscribeBtnTxt : Styles.SubscribeBtnTxt
              }
            >
              Start your free trial
            </p>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default PremiumPlans;
