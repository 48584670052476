import { motion } from "framer-motion";
import { hRatio } from "../../constants/utils";

const SlideTransition = ({ children, className="" }) => {
    return (
            <motion.div
                className={className}
                animate={{opacity:1, y:0}}
                exit={{   opacity:0 }}
                transition={{ duration: 1}}
            >
            {children}
            </motion.div>
    );
}

const FadeTransition = ({ children, className=""}) => {
    return (
            <motion.div
                className={className}
                initial={{opacity:1}}
                animate={{opacity:1, y:0}}
                exit={{   opacity:1 }}
                transition={{ duration: 0.3}}
            >
            {children}
            </motion.div>
    );
}

const SlideIn = ({children, className=""}) => {
    return(
        <motion.div
            className={className}
            initial={{opacity:0, x:50*hRatio}}
            animate={{opacity:1, x:0}}
            exit={{ opacity:0,  x:-50*hRatio }}
            transition={{ duration: 0.7}}
        >
            {children}
        </motion.div>
    )
}

export {SlideTransition, SlideIn, FadeTransition}
